import React from 'react'
import styled from 'styled-components'
import ProductTile from './ProductTile'
import { StickyContainer, Sticky } from 'react-sticky';

const ProductsGridWrapper = styled.section`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr;

    @media(min-width: 384px) {
        grid-template-columns: 1fr;
    }

    @media(min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    @media(min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;

const ProductsGrid = ({ products }) => {
    return (
        <ProductsGridWrapper>
        {/* <StickyContainer>
            <Sticky>
         {({ style, isSticky }) => <div style={{ ...style, paddingTop: isSticky ? '130px' : '0px', boxShadow: isSticky ? ' 0px 3px 5px 0px rgba(0,0,0,0.5)' : 'none', zIndex: '100', backgroundColor: 'white' }}><h2 style={{textAlign: 'center'}}>Use code <span style={{fontSize: 'larger', color: 'green'}}>SSN799</span> at checkout to receive 20% off your order!</h2></div>}
    </Sticky> */}
                {/* <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}> */}
                {products.map(product => (
                    <ProductTile
                        key={product.id}
                        title={product.title}
                        images={product.images}
                        description={product.description}
                        minPrice={product.priceRange?.minVariantPrice?.amount}
                        handle={product.handle}
                    />
                ))}
                {/* </div> */}
       {/* </StickyContainer> */}
    </ProductsGridWrapper>
    )
}

export default ProductsGrid