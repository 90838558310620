import React from 'react'
import styled from 'styled-components'
import { StyledLink } from '../StyledLink/StyledLink'

const ProductTileWrapper = styled.div`
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    padding: 8px;
    width: 100%;
    margin: 0.5rem;

    @media (max-width: 768px) { 
        width: 100%;
    }

    > ${StyledLink} {
        color: white;
        text-align: center;
        padding-bottom: .3rem;

        &:hover {
            text-decoration: none;
        }
    }
`;

const ProductImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin: 20px;
    text-align: center;
`;

const Price = styled.div`
    font-style: italic;
    padding: 20px;
    font-weight: bold;
    margin-top: auto;
`;

const ProductTile = ({ title, images, description, minPrice, handle }) => {
    const firstImage = images?.[0];

    return (
        <ProductTileWrapper>
            {firstImage && (
                <ProductImage 
                    src={firstImage.url} 
                    alt={firstImage.altText || title}
                />
            )}
            <Title>
                {title}
            </Title>
            {/* <Description>
                {description}
             </Description> */}
             <Price>
                from ${parseFloat(minPrice).toFixed(2)}
             </Price>
             <StyledLink to={`/products/${handle}`} className="ussIdahoButton">View Product</StyledLink>
            
        </ProductTileWrapper>
    )
}

export default ProductTile