import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import ProductContext from "../context/ProductContext"
import styled from "styled-components"
import ProductGrid from "../components/Products/ProductsGrid"
import queryString from "query-string"
import { useLocation } from "@reach/router"
import FeaturedProductTile from "../components/Products/FeaturedProductTile"

const Content = styled.div`
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
`

const ProductsPage = () => {
  const { products, collections } = React.useContext(ProductContext)
  const collectionProductMap = {}
  const [featuredProduct, setFeaturedProduct] = React.useState([])
  const { search } = useLocation()
  const qs = queryString.parse(search)
  const selectedCollectionIds = qs.c?.split(",").filter(c => !!c) || []
  const selectedCollectionIdsMap = {}

  selectedCollectionIds.forEach(collectionId => {
    selectedCollectionIdsMap[collectionId] = true
  })

  React.useEffect(() => {
    if (collections) {
      collections.forEach(collection => {
        collectionProductMap[collection.id] = {}
        collection.products.forEach(product => {
          collectionProductMap[collection.id][product.id] = true
        })
      })

      const featuredCollection = collections.find(c => c.title === "Featured")
      if (featuredCollection) {
        setFeaturedProduct(featuredCollection.products)
      }
    }
  }, [collections])

  const filterByCategory = product => {
    if (Object.keys(selectedCollectionIdsMap).length) {
      for (let key in selectedCollectionIdsMap) {
        if (collectionProductMap[key]?.[product.id]) {
          return true
        }
      }
      return false
    }
    return true
  }

  const filteredProducts = products.filter(filterByCategory)

  return (
    <Layout>
      <SEO title="Cart" />
      <Container fluid className="container-fluid-full">
        <PageTitle
          subPage="USS IDAHO Merchandise"
          pageTitle="USS IDAHO Merchandise"
          link="/"
        />
        <ContentBlock>
          <Content>
            <div>
              {featuredProduct.length > 0 && (
                <div>
                  {featuredProduct[0].images?.[0] && (
                    <FeaturedProductTile
                      title={featuredProduct[0].title}
                      minPrice={
                        featuredProduct[0].priceRange.minVariantPrice.amount
                      }
                      handle={featuredProduct[0].handle}
                      image={featuredProduct[0].images[0]}
                      imageUrl={featuredProduct[0].images[0].url}
                      imageAlt={featuredProduct[0].images[0].altText || featuredProduct[0].title}
                    />
                  )}
                </div>
              )}

              <ProductGrid products={filteredProducts} />
            </div>
          </Content>
        </ContentBlock>
      </Container>
    </Layout>
  )
}

export default ProductsPage
